import { template as template_8050be7c011740b39b2be839cddbabe9 } from "@ember/template-compiler";
const WelcomeHeader = template_8050be7c011740b39b2be839cddbabe9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
