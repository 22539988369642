import { template as template_ed1300670cb542ee9ecc6f21534f8ed7 } from "@ember/template-compiler";
const FKText = template_ed1300670cb542ee9ecc6f21534f8ed7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
