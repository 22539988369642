import { template as template_5a69bf87487347d19160ed15339a9d0d } from "@ember/template-compiler";
const FKLabel = template_5a69bf87487347d19160ed15339a9d0d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
